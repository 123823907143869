<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        تحديث وسيلة الاتصال
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addContactUsFormalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="العنوان"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addContactUsForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="الموقع"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="location"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addContactUsForm.location"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="الرقم "
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
              >
                <b-form-input
                  v-model="addContactUsForm.phone"
                  type="text"
                  class="phone"
                />

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="الايميل "
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
              >
                <b-form-input
                  v-model="addContactUsForm.email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="ساعات العمل  "
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Working Hours"
                rules="required"
              >
                <b-form-input
                  v-model="addContactUsForm.working_hours"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const validLink = ref(true)
    const checkLink = link => {
      const formData = new FormData()

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData)
        .then(response => {
          validLink.value = response.data.valid
        })
    }
    const refPreviewE2 = ref(null)

    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
    console.log(id)
    store.dispatch('contactUs/GetContactUs', { id }).then(response => {
      store.commit('contactUs/GET_CONTACT_US', response?.data)
    })

    const ContactUsForm = computed(() => store.state.contactUs.contactus.data)
    const addContactUsForm = reactive(ContactUsForm)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addContactUsFormalidate = ref()
    const removeSpace = target => {
      addContactUsForm.value.link_id = target.toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''""=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const save = () => {
      const formData = new FormData()
console.log(addContactUsForm.value)
      formData.append('title', addContactUsForm.value.title)
      formData.append('location', addContactUsForm.value.location)
      // formData.append("link_id", addContactUsForm.value.link_id);
      formData.append('phone', addContactUsForm.value.phone)
      formData.append('email', addContactUsForm.value.email)
      formData.append('working_hours', addContactUsForm.value.working_hours)

      addContactUsFormalidate.value.validate().then(success => {
        if (success) {
          store
            .dispatch('contactUs/UpdateCategory', { id, formData })
            .then(response => {
              Vue.swal({
                icon: 'success',
                title: 'تم التحديث',
                confirmButtonText: 'حسنا',

                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    }
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addContactUsForm,
      inputImageRenderer,
      addContactUsFormalidate,
      id,
      ContactUsForm,
      removeSpace,
      validLink,
      checkLink,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,

    ValidationProvider,
    ValidationObserver,
    BForm,
  
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }

  </style>
